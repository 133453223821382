import React from "react";

import { BuildingItemType } from "./buildingPositions";

import brickImageTier1 from "../../../content/assets/building/t1_brick.svg";
import brickImageTier2 from "../../../content/assets/building/t2_brick.svg";
import brickImageTier3 from "../../../content/assets/building/t3_brick.svg";
import doorImageTier1 from "../../../content/assets/building/t1_door.svg";
import roofImageTier1 from "../../../content/assets/building/t1_roof.svg";
import roofImageTier2 from "../../../content/assets/building/t2_roof.svg";
import roofImageTier3 from "../../../content/assets/building/t3_roof.svg";
import windowImageTier1 from "../../../content/assets/building/t1_window.svg";
import windowImageTier2 from "../../../content/assets/building/t2_window.svg";
import windowImageTier3 from "../../../content/assets/building/t3_window.svg";

// import styles from "./Acorn.module.css";

type BuildingItemPropsType = {
  buildingItemType: BuildingItemType;
}
export function BuildingItem({ buildingItemType }: BuildingItemPropsType) {
  
  return (
    <div
      className="relative w-16 flex justify-center align-middle"
      style={{
        cursor: "inherit",
        // aspectRatio: 33 / 14,
        // width: 64,
        // height: undefined,
      }}
    >
      {/* BRICK */}
      {buildingItemType === 0 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(33/14)}`,
          width: 50,
          height: undefined,
          maxWidth: 'none'
        }}
        alt=""
        src={brickImageTier1}
        width="50"
        height="50"
      />}
      {buildingItemType === 1 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(33/14)}`,
          width: 50,
          height: undefined,
          maxWidth: 'none'
        }}
        alt=""
        src={brickImageTier2}
        width="50"
        height="50"
      />}
      {buildingItemType === 2 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(33/14)}`,
          width: 50,
          height: undefined,
          maxWidth: 'none'
        }}
        alt=""
        src={brickImageTier3}
        width="50"
        height="50"
      />}

      {/* WINDOW */}
      {buildingItemType === 3 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(31/45)}`,
          height: undefined,
          width: 40,
          maxWidth: 'none'
        }}
        alt=""
        src={windowImageTier1}
        width="80"
        height="160"
      />}
      {buildingItemType === 4 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio:`${(101/61)}`,
          height: undefined,
          width: 100,
          maxWidth: 'none'
        }}
        alt=""
        src={windowImageTier2}
        width="80"
        height="160"
      />}
      {buildingItemType === 5 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(66/66)}`,
          height: undefined,
          width: 66,
          maxWidth: 'none'
        }}
        alt=""
        src={windowImageTier3}
        width="80"
        height="160"
      />}

      {/* ROOF */}
      {buildingItemType === 6 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(237/43)}`,
          height: undefined,
          width: 350,
          maxWidth: 'none'
        }}
        alt=""
        src={roofImageTier1}
        width="80"
        height="160"
      />}
      {buildingItemType === 7 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(237/43)}`,
          height: undefined,
          width: 350,
          maxWidth: 'none'
        }}
        alt=""
        src={roofImageTier2}
        width="80"
        height="160"
      />}
      {buildingItemType === 8 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(237/43)}`,
          height: undefined,
          width: 350,
          maxWidth: 'none'
        }}
        alt=""
        src={roofImageTier3}
        width="80"
        height="160"
      />}

      {/* DOOR */}
      {buildingItemType === 9 && <img
        style={{
          transform: `scale(0.8)`,
          aspectRatio: `${(31/61)}`,
          height: undefined,
          width: 45,
          maxWidth: 'none'
        }}
        alt=""
        src={doorImageTier1}
        width="80"
        height="160"
      />}

    </div>
  );
}

export default BuildingItem;
