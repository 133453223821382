import React, { useState, useEffect, useRef, useCallback } from 'react';

// import styles from './'
import Ant from './Ant';
import Bird from './Bird';
// import './Building.module.css';

import {
  buildingPositionsTier1, buildingPositionsTier2, buildingPositionsTier3,
  BuildingItemPositionType, BuildingItemType
} from './buildingPositions'

import brickImageTier1 from '../../../content/assets/building/t1_brick.svg'
import brickImageTier2 from '../../../content/assets/building/t2_brick.svg'
import brickImageTier3 from '../../../content/assets/building/t3_brick.svg'
import doorImageTier1 from '../../../content/assets/building/t1_door.svg'
import roofImageTier1 from '../../../content/assets/building/t1_roof.svg'
import roofImageTier2 from '../../../content/assets/building/t2_roof.svg'
import roofImageTier3 from '../../../content/assets/building/t3_roof.svg'
import windowImageTier1 from '../../../content/assets/building/t1_window.svg'
import windowImageTier2 from '../../../content/assets/building/t2_window.svg'
import windowImageTier3 from '../../../content/assets/building/t3_window.svg'

export type TierType = 1 | 2 | 3

// const styles = new StyleSheet(.cls1 {
//   fill: none,
//   stroke: #d1ecf2,
//   stroke-miterlimit: 10,
//   stroke-width: 2.57px;
// })

type AntStepsType = {
  ani: number,
  aniReverse: number,
  sec: number,
  rot: number,
  rotReverse: number,
  x: number,
  y: number,
  scale: number,
  transSec: number
}[]

type AntType = {
  antUID: number,
  antSteps: AntStepsType,
  rightSide: boolean,
  aniNum: number,
  stepIndex: number,
  startTime: number,
  x: number,
  y: number,
  rotate: number,
  scale: number,
  transSec: number,
  reverse: boolean,
  secondsPassedWhenReversed: number,
  buildingItemType: BuildingItemType,
  carriedItemID: string,
  carryingItem: boolean,
  removeItem: boolean
}
type BirdType = {
  rightSide: boolean,
  aniNum: number,
  stepIndex: number,
  startTime: number,
  x: number,
  y: number,
  rotate: number,
  scale: number,
  transSec: number,
  reverse: boolean,
  secondsPassedWhenReversed: number,
  buildingItemType: BuildingItemType,
  carriedItemID: string,
  carryingItem: boolean,
  removeItem: boolean
} | null

export enum BuildingItemState {
  Waiting = 0,
  Moving = 1,
  Done = 2,
}
export type BuildingItemStateType = {
  id: string;
  type: BuildingItemType;
  rotation: number;
  state: BuildingItemState;
}
export enum TierCompletionProgressType {
  'Incomplete' = 0,
  'InProgress' = 1,
  'Complete' = 2
}
type BuildingItemStatusType = {
  id: string,
  done: boolean
}

type BuildingPropsType = {
  _paused: boolean,
  _tier: TierType,
}

export function Building({ _paused, _tier }: BuildingPropsType) {

  // *** `paused` PROP STATE ***
  const [paused, setPaused] = useState(_paused)
  const updatePaused = (p: boolean) => {
    setPaused(p)
  }
  useEffect(() => {
    updatePaused(_paused)
  }, [_paused])

  // *** `tier` PROP STATE ***
  const [tier, seTier] = useState<TierType>(_tier)
  const updateTier = (t: TierType) => {
    seTier(t)
  }
  useEffect(() => {
    updateTier(_tier)
  }, [_tier])

  // const tier = useAppSelector(selectCount);
  // const dispatch = useAppDispatch();
  // const [incrementAmount, setIncrementAmount] = useState('1');
  // const incrementValue = Number(incrementAmount) || 0;

  const buildingRef = useRef<HTMLDivElement>(null)

  // ***************** ANTS & BIRD STATES *****************
  const [ants, setAnts] = useState<AntType[]>([])
  const [bird, setBird] = useState<BirdType>(null)
  const [antUidIndex, setAntUidIndex] = useState(0)
  const [secondsPassed, setSecondsPassed] = useState(0)
  const [antAddCountdown, setAntAddCountdown] = useState(0)
  const updateAntAddCountdown = (c: number) => {
    setAntAddCountdown(c)
  }
  const updateAntUidIndex = (i: number) => {
    setAntUidIndex(i)
  }
  const updateAntsArray = (a: AntType[]) => {
    setAnts(a)
  }
  const updateSecondsPassed = (t: number) => {
    setSecondsPassed(t)
  }

  // ************** TESTING SETTINGS **************
  let testing = false;
  let testingTier = 1;
  let testingItemsUnfinished = 2

  // ************** BUILDING ITEM STATES **************
  const [tier1Index, setTier1Index] = useState(() => {
    if (testing && testingTier === 2) {
      return buildingPositionsTier1.length - 1
    } else if (testing) {
      return buildingPositionsTier1.length - (testingItemsUnfinished + 2)
    }
    return 0
  });
  const [tier2Index, setTier2Index] = useState(() => {
    if (testing && testingTier === 2) {
      return buildingPositionsTier2.length - (testingItemsUnfinished + 2)
    }
    return 0
  });
  const [tier3Index, setTier3Index] = useState(0);
  const updateTier1Index = (i: number) => { setTier1Index(i) }
  const updateTier2Index = (i: number) => { setTier2Index(i) }
  const updateTier3Index = (i: number) => { setTier3Index(i) }

  // testingTier can be 1, 2, or 3

  const [buildingItemsTier1Status, setBuildingItemsTier1Status] = useState<BuildingItemStatusType[]>(
    buildingPositionsTier1.map((item, i) => {
      if (testing) {
        if (testingTier === 1 && i > buildingPositionsTier1.length - testingItemsUnfinished) {
          return { id: item.id, done: false }
        }
        return { id: item.id, done: true }
      }
      return { id: item.id, done: false }
    })
  )
  const [buildingItemsTier2Status, setBuildingItemsTier2Status] = useState(
    buildingPositionsTier2.map((item, i) => {
      // if (testing && testingTier === 2) {
      //   if (i > buildingPositionsTier2.length - testingItemsUnfinished) {
      //     return { id: item.id, done: false }
      //   }
      //   return { id: item.id, done: true }
      // }
      return { id: item.id, done: false }
    })
  )
  const [buildingItemsTier3Status, setBuildingItemsTier3Status] = useState(
    buildingPositionsTier3.map((item, i) => { return { id: item.id, done: false } })
  )
  const updateBuildingItemsTier1Status = (s: BuildingItemStatusType[]) => { setBuildingItemsTier1Status(s) }
  const updateBuildingItemsTier2Status = (s: BuildingItemStatusType[]) => { setBuildingItemsTier2Status(s) }
  const updateBuildingItemsTier3Status = (s: BuildingItemStatusType[]) => { setBuildingItemsTier3Status(s) }



  // * ANT STEP INDEXES WHERE BUILDING ITEM IS PLACED...
  const antStepIndexToPlaceItem_0 = 2;
  const antStepIndexToPlaceItem_1 = 3;
  const antStepIndexToPlaceItem_2 = 4;
  const antStepIndexToPlaceItem_3 = 5;

  // testing...
  // const antStepIndexToPlaceItem_0 = 1;
  // const antStepIndexToPlaceItem_1 = 2;
  // const antStepIndexToPlaceItem_2 = 3;
  // const antStepIndexToPlaceItem_3 = 4;

  // const antStepIndexToPlaceItemRightSide = 5;
  // const birdStepIndexToCheckAcornRightSide = 8;
  const antFrequencyInSeconds = 0.5;

  // **********  ani  1: walk, 2: walk LTR, 3: carry, 4: carry LTR ********** 
  const maxAnts = 20;
  // let fastAnts = false;
  let antHeight = 64;
  let floorY = 273 - antHeight / 2;
  // ****** GOOD ANT ANIMATION,,,
  const antSteps: AntStepsType = [
    { ani: 4, aniReverse: 1, sec: 0, rot: 0, rotReverse: 0, x: -2000, y: floorY, scale: 1.0, transSec: 1.0 },
    { ani: 4, aniReverse: 1, sec: 1, rot: 0, rotReverse: 0, x: -300, y: floorY, scale: 1.0, transSec: 1.0 },
    { ani: 4, aniReverse: 1, sec: 2, rot: 0, rotReverse: 0, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
    { ani: 4, aniReverse: 1, sec: 5, rot: 0, rotReverse: 0, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
    { ani: 4, aniReverse: 1, sec: 6, rot: 3, rotReverse: 0, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
    { ani: 1, aniReverse: 2, sec: 7, rot: 3, rotReverse: 2, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
    { ani: 1, aniReverse: 2, sec: 8, rot: 0, rotReverse: 0, x: -300, y: floorY, scale: 1.0, transSec: 2.0 },
    { ani: 1, aniReverse: 2, sec: 10, rot: 0, rotReverse: 0, x: -2000, y: floorY, scale: 1.0, transSec: 1.0 }
  ];

  // * _______ TESTING SUPER FAST ANTS ________
  // const antSteps: AntStepsType = [
  //   { ani: 4, aniReverse: 1, sec: 0, rot: 0, rotReverse: 0, x: -2000, y: floorY, scale: 1.0, transSec: 1.0 },
  //   { ani: 4, aniReverse: 1, sec: 1, rot: 0, rotReverse: 0, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
  //   { ani: 4, aniReverse: 1, sec: 2, rot: 0, rotReverse: 0, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
  //   { ani: 4, aniReverse: 1, sec: 3, rot: 3, rotReverse: 0, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
  //   { ani: 1, aniReverse: 2, sec: 4, rot: 3, rotReverse: 0, x: 2, y: floorY, scale: 1.0, transSec: 1.0 },
  //   { ani: 1, aniReverse: 2, sec: 5, rot: 0, rotReverse: 0, x: -2000, y: floorY, scale: 1.0, transSec: 1.0 }
  // ];

  /**
  *   ani  1: fly, 2: fly LTR, 3: settle, 4: pick
  *   ant steps with 4.0 second css transion
  */
  const birdStepIndexToCheckBuildingItem = 3;
  const birdSteps = [
    { ani: 2, aniReverse: 1, sec: 0, x: -800, y: -200, transSec: 1.0 },
    { ani: 2, aniReverse: 1, sec: 1, x: 100, y: 0, transSec: 1.0 },
    { ani: 2, aniReverse: 1, sec: 3, x: 100, y: 200, transSec: 1.0 },
    { ani: 3, aniReverse: 1, sec: 4, x: 100, y: 200, transSec: 1.0 },
    { ani: 1, aniReverse: 1, sec: 5, x: 100, y: 0, transSec: 1.0 },
    { ani: 1, aniReverse: 1, sec: 7, x: -800, y: -200, transSec: 1.0 }];

  // const birdStepsRightSide = [
  //   { ani: 1, aniReverse: 2, sec: 0, x: 650, y: -400, transSec: 1.0 },
  //   { ani: 1, aniReverse: 2, sec: 2, x: 550, y: -200, transSec: 2.0 },
  //   { ani: 1, aniReverse: 2, sec: 4, x: 310, y: 400, transSec: 2.0 },
  //   { ani: 1, aniReverse: 2, sec: 6, x: 310, y: 100, transSec: 2.0 },
  //   { ani: 1, aniReverse: 2, sec: 7, x: 310, y: 400, transSec: 1.0 },
  //   { ani: 1, aniReverse: 2, sec: 8, x: 310, y: 100, transSec: 1.0 },
  //   { ani: 1, aniReverse: 2, sec: 9, x: 140, y: 450, transSec: 1.0 },
  //   { ani: 1, aniReverse: 2, sec: 11, x: 140, y: 450, transSec: 2.0 },
  //   { ani: 1, aniReverse: 2, sec: 13, x: -200, y: -400, transSec: 2.0 },
  //   { ani: 1, aniReverse: 2, sec: 15, x: -200, y: -500, transSec: 2.0 }];

  // ****************** BUILDING TIMER ******************
  let intervalRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      // console.log('[BUILDING] timer interval  |  secondsPassed: ' + secondsPassed);
      if (!paused) {

        // ****** ADD ANTS ******
        let antsModified = [...ants]
        if (antAddCountdown <= 0) {

          // ****** CHECK CURRENT TOTAL TIER PROGRESS ******          
          let totalBuildingProgress: TierCompletionProgressType[] = [0, 0, 0]
          let currentBuildingTier = 1
          let roofComplete = [false, false, false]

          // ** CHECK TIER 1 PROGRESS **
          let allCompleteTier1 = true
          let anyCompleteTier1 = 0
          buildingItemsTier1Status.forEach(item => {
            if (item.done === true) {
              anyCompleteTier1 = 1
            }
            if (item.done === false) {
              if (item.id === 'T1_ROOF') {
                // ignore...
              } else {
                allCompleteTier1 = false
              }
            }
            if (item.id === 'T1_ROOF') {
              roofComplete[0] = item.done
            }
          });
          if (allCompleteTier1 === true) {
            totalBuildingProgress[0] = 2;
          } else if (anyCompleteTier1 === 1) {
            totalBuildingProgress[0] = 1
          } else {
            totalBuildingProgress[0] = 0
          }

          // ** CHECK TIER 2 PROGRESS **
          let allCompleteTier2 = true
          let anyCompleteTier2 = 0
          buildingItemsTier2Status.forEach(item => {
            if (item.done === true) {
              anyCompleteTier2 = 1
            }
            if (item.done === false) {
              if (item.id === 'T2_ROOF') {
                // ignore...
              } else {
                allCompleteTier2 = false
              }
            }
            if (item.id === 'T2_ROOF') {
              roofComplete[1] = item.done
            }
          });
          if (allCompleteTier2 === true) {
            totalBuildingProgress[1] = 2;
          } else if (anyCompleteTier2 === 1) {
            totalBuildingProgress[1] = 1
          } else {
            totalBuildingProgress[1] = 0
          }

          // ** CHECK TIER 3 PROGRESS **
          let allCompleteTier3 = true
          let anyCompleteTier3 = 0
          buildingItemsTier3Status.forEach(item => {
            if (item.done === true) {
              anyCompleteTier3 = 1
            }
            if (item.done === false) {
              allCompleteTier3 = false
            }
            if (item.id === 'T3_ROOF') {
              roofComplete[2] = item.done
            }
          });
          if (allCompleteTier3 === true) {
            totalBuildingProgress[2] = 2;
          } else if (anyCompleteTier3 === 1) {
            totalBuildingProgress[2] = 1
          } else {
            totalBuildingProgress[2] = 0
          }

          // console.log(`[BUILDING] timer interval  | totalBuildingProgress:`, totalBuildingProgress);
          // console.log(`[BUILDING] timer interval  | roofComplete:`, roofComplete);

          // ****** CHECK CURRENT TIER AND DETERMINE IF ANTS SHOULD ADD OR REMOVE BUILDING ITEMS ******
          let removeItem = false
          if (totalBuildingProgress[0] === 2 && totalBuildingProgress[1] !== 2) {
            // ******** FINISHED TIER 1, BUT NOT TIER 2... ********
            if (tier === 1) {
              // * TIER IS 1, REMOVE ANY TIER 2 ITEMS... 
              if (totalBuildingProgress[1] > 0) {
                currentBuildingTier = 2
                removeItem = true;
                // console.log(`[BUILDING] timer interval  | TIER IS 1, REMOVE ANY TIER 2 ITEMS... `);
              } else {
                // * ADD BIRD TO ADD ROOF...
                if (roofComplete[0] === false && bird === null) {
                  addBird('T1_ROOF', 6, false)
                }
              }
            } else if (tier > 1) {
              // * TIER IS GREATER THEN 1, SO PROCEED TO BUILD TIER 2... 
              currentBuildingTier = 2
            } else {
              // * TIER IS 1, SO ALL SET...
              currentBuildingTier = 1
              // TODO - SO CHECK IF ROOF ID REQUIRED...               
            }
          } else if (totalBuildingProgress[0] === 2 && totalBuildingProgress[1] === 2 && totalBuildingProgress[2] !== 2) {
            // ******** FINISHED TIER 2, BUT NOT TIER 3... ********
            if (tier === 2) {
              // * TIER IS 2, REMOVE ANY TIER 3 ITEMS... 
              if (totalBuildingProgress[2] > 0) {
                currentBuildingTier = 3
                removeItem = true;
              } else {
                // * ADD BIRD TO ADD T2 ROOF...
                if (roofComplete[1] === false && bird === null) {
                  addBird('T2_ROOF', 7, false)
                }
              }
            } else if (tier === 3) {
              // * TIER IS GREATER THEN 2, SO PROCEED TO BUILD TIER 3... 
              currentBuildingTier = 3
              // // * ADD BIRD TO ADD T3 ROOF...
              // if (roofComplete[2] === false && bird === null) {
              //   addBird('T3_ROOF', 8, false)
              // }
            } else if (tier === 1 && totalBuildingProgress[2] === 0) {
              // * TIER IS 1, AND TIER 3 IS REMOVED OR NOT BUILT, SO REMOVE ANY TIER 2 BUILDING PARTS... 
              currentBuildingTier = 2
              removeItem = true;
            } else if (tier === 1) {
              // * TIER IS 1, AND TIER 3 IS NOT REMOVED, SO REMOVE ANY TIER 3 BUILDING PARTS...  
              currentBuildingTier = 3
              removeItem = true;
            } else {
              // * TIER IS 2, SO ALL SET...
              currentBuildingTier = 2
              // TODO - SO CHECK IF ROOF ID REQUIRED...               
            }
          } else if (totalBuildingProgress[0] === 2 && totalBuildingProgress[1] === 2 && totalBuildingProgress[2] === 2) {
            // ******** FINISHED TIER 3... ********
            if (tier < 3) {
              // * TIER IS LESS THEN 3, SO REMOVE ANY TIER 3 BUILDING PARTS... 
              currentBuildingTier = 3
              removeItem = true;
            } else {
              // * TIER IS 3, SO ALL SET...
              currentBuildingTier = 3
              // TODO - SO CHECK IF ROOF ID REQUIRED...               
            }
          }
          // console.log(`[BUILDING] timer interval  | removeItem: ${removeItem} currentBuildingTier: `, currentBuildingTier);
          // console.log(`[BUILDING] timer interval  | tier1Index: ${tier1Index} tier2Index: ${tier2Index} tier3Index: ${tier3Index} `);

          // ****** ADD ANY ANTS FOR REMOVING OR ADDING BUILDING ITEMS ******
          if (removeItem === true) {
            // ****** REMOVING BUILDING ITEMS *******
            if (currentBuildingTier === 2 && totalBuildingProgress[1] !== 0) {
              // * CHECK IF ROOF TIER 2 NEEDS TO BE REMOVED...
              if (roofComplete[1] === true && bird === null) {
                // * ADD BIRD TO REMOVE ROOF...
                addBird('T2_ROOF', 7, true)
              }
              if (tier2Index < buildingItemsTier2Status.length && totalBuildingProgress[1] > 0) {
                antsModified = addAnt(antsModified, antUidIndex, secondsPassed, 2, tier1Index, tier2Index, tier3Index, true);
              }
            } else if (currentBuildingTier === 3 && totalBuildingProgress[2] !== 0) {
              // * CHECK IF ROOF TIER 3 NEEDS TO BE REMOVED...
              if (roofComplete[2] === true && bird === null) {
                // * ADD BIRD TO REMOVE ROOF...
                addBird('T3_ROOF', 8, true)
              }
              if (tier3Index < buildingItemsTier3Status.length && totalBuildingProgress[2] > 0) {
                antsModified = addAnt(antsModified, antUidIndex, secondsPassed, 3, tier1Index, tier2Index, tier3Index, true);
              }
            }
          } else {
            // ****** ADDING BUILDING ITEMS *******
            if (currentBuildingTier === 1 && totalBuildingProgress[0] !== 2) {
              if (tier1Index < buildingItemsTier1Status.length) {
                antsModified = addAnt(antsModified, antUidIndex, secondsPassed, 1, tier1Index, tier2Index, tier3Index, false);
              }
            } else if (currentBuildingTier === 2 && totalBuildingProgress[1] !== 2) {
              // * CHECK IF ROOF TIER 1 NEEDS TO BE REMOVED...
              if (roofComplete[0] === true && bird === null) {
                // * ADD BIRD TO REMOVE ROOF...
                addBird('T1_ROOF', 6, true)
              }
              if (tier2Index < buildingItemsTier2Status.length) {
                antsModified = addAnt(antsModified, antUidIndex, secondsPassed, 2, tier1Index, tier2Index, tier3Index, false);
              }
            } else if (currentBuildingTier === 3 && totalBuildingProgress[2] !== 2) {
              // * CHECK IF ROOF TIER 2 NEEDS TO BE REMOVED...
              if (roofComplete[1] === true && bird === null) {
                // * ADD BIRD TO REMOVE ROOF...
                addBird('T2_ROOF', 7, true)
              }
              if (tier3Index < buildingItemsTier3Status.length) {
                antsModified = addAnt(antsModified, antUidIndex, secondsPassed, 3, tier1Index, tier2Index, tier3Index, false);
              }
            }
          }

          updateAntAddCountdown((antFrequencyInSeconds + randomIntInRange(0, 1)));
        } else {
          updateAntAddCountdown(antAddCountdown - 1);
        }

        // ****** UPDATE ANTS ******
        antsModified = updateAnts(antsModified, secondsPassed + 1, tier);

        // ****** UPDATE MODIFUED ANTS ARRAY ******
        // antsModified = removeDoneAnts(antsModified)

        // ****** UPDATE ANTS ARRAY ******
        updateAntsArray(antsModified);

        // ****** UPDATE BIRD ******
        updateBird();

        updateSecondsPassed(secondsPassed + 1);

      }

    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [secondsPassed, paused, ants, bird, antUidIndex, antAddCountdown, tier, tier1Index, tier2Index, tier3Index]);

  // ****************** BUILDING ITEMS ******************
  const buildingItem = useCallback((index: number, item: BuildingItemPositionType, tier: number) => {
    let itemImage = doorImageTier1;
    if (item.type === BuildingItemType.Brick_tier1) {
      itemImage = brickImageTier1
    } else if (item.type === BuildingItemType.Brick_tier2) {
      itemImage = brickImageTier2
    } else if (item.type === BuildingItemType.Brick_tier3) {
      itemImage = brickImageTier3
    } else if (item.type === BuildingItemType.Door_tier1) {
      itemImage = doorImageTier1
    } else if (item.type === BuildingItemType.Window_tier1) {
      itemImage = windowImageTier1
    } else if (item.type === BuildingItemType.Window_tier2) {
      itemImage = windowImageTier2
    } else if (item.type === BuildingItemType.Window_tier3) {
      itemImage = windowImageTier3
    } else if (item.type === BuildingItemType.Roof_tier1) {
      itemImage = roofImageTier1
    } else if (item.type === BuildingItemType.Roof_tier2) {
      itemImage = roofImageTier2
    } else if (item.type === BuildingItemType.Roof_tier3) {
      itemImage = roofImageTier3
    }

    let showImage = false;
    if (buildingItemsTier1Status.length > index) {
      // console.log(buildingItemsTier1Status[index]);
      showImage = buildingItemsTier1Status[index].done ? true : false
    }
    if (tier > 1 && buildingItemsTier2Status.length > index) {
      // console.log(buildingItemsTier2Status[index]);
      showImage = buildingItemsTier2Status[index].done ? true : false
    }
    if (tier > 2 && buildingItemsTier3Status.length > index) {
      // console.log(buildingItemsTier3Status[index]);
      showImage = buildingItemsTier3Status[index].done ? true : false
    }

    if (showImage === true) {
      return <img
        key={index}
        id={`${item.id}`}
        src={itemImage}
        style={{
          position: 'absolute',
          top: `${item.top}px`,
          left: `${item.left}px`
        }}
        width={item.width}
        height={item.height} alt="" />

    } else {
      return ''
    }
  }, [buildingItemsTier1Status, buildingItemsTier2Status, buildingItemsTier3Status]);

  const buildingItemPlaced = useCallback((buildingItemID: string, removeItem: boolean) => {
    // console.log('[BUILDING] buildingItemPlaced |  buildingItemID: ' + buildingItemID + ' removeItem: ' + removeItem);
    const indexItemTier1 = buildingItemsTier1Status.findIndex(item => {
      return item.id === buildingItemID;
    });
    const indexItemTier2 = buildingItemsTier2Status.findIndex(item => {
      return item.id === buildingItemID;
    });
    const indexItemTier3 = buildingItemsTier3Status.findIndex(item => {
      return item.id === buildingItemID;
    });
    if (indexItemTier1 !== -1 && buildingItemsTier1Status.length > indexItemTier1) {
      let buildingItemsTier1StatusModified = [...buildingItemsTier1Status]
      buildingItemsTier1StatusModified[indexItemTier1].done = removeItem ? false : true
      updateBuildingItemsTier1Status(buildingItemsTier1StatusModified)
    } else if (indexItemTier2 !== -1 && buildingItemsTier2Status.length > indexItemTier2) {
      let buildingItemsTier2StatusModified = [...buildingItemsTier2Status]
      buildingItemsTier2StatusModified[indexItemTier2].done = removeItem ? false : true
      updateBuildingItemsTier2Status(buildingItemsTier2StatusModified)
    } else if (indexItemTier3 !== -1 && buildingItemsTier3Status.length > indexItemTier3) {
      let buildingItemsTier3StatusModified = [...buildingItemsTier3Status]
      buildingItemsTier3StatusModified[indexItemTier3].done = removeItem ? false : true
      updateBuildingItemsTier3Status(buildingItemsTier3StatusModified)
    }
  }, [buildingItemsTier1Status, buildingItemsTier2Status, buildingItemsTier3Status])


  // ***************** ANTS *****************
  const addAnt = (ants: AntType[], antUidIndex: number, secondsPassed: number,
    tier: number, tier1Index: number, tier2Index: number, tier3Index: number, removeItem: boolean): AntType[] => {
    // console.log(`[BUILDING] addAnt | total ants: ${ants.length}`);
    // console.log(`[BUILDING] addAnt | tier: ${tier}  tier1Index: ${tier1Index}  tier2Index: ${tier2Index}  tier3Index: ${tier3Index}  removeItem: ${removeItem} `);

    // if (ants.length > maxAnts) {
    //   return ants;  // max of 7 ants
    // }
    let rightSide = false;

    // *** BUILDING ITEM ***
    let buildingItemType = 0;
    let buildingItemX = 0;
    let buildingItemY = 0;
    let buildingItemID = '';
    if (tier === 1 && buildingPositionsTier1.length > tier1Index) {
      buildingItemID = buildingPositionsTier1[tier1Index].id
      buildingItemType = buildingPositionsTier1[tier1Index].type
      buildingItemX = buildingPositionsTier1[tier1Index].left - 30
      buildingItemY = buildingPositionsTier1[tier1Index].top
    } else if (tier === 2 && buildingPositionsTier2.length > tier2Index) {
      buildingItemID = buildingPositionsTier2[tier2Index].id
      buildingItemType = buildingPositionsTier2[tier2Index].type
      buildingItemX = buildingPositionsTier2[tier2Index].left - 30
      buildingItemY = buildingPositionsTier2[tier2Index].top - 100
    } else if (tier === 3 && buildingPositionsTier3.length > tier3Index) {
      buildingItemID = buildingPositionsTier3[tier3Index].id
      buildingItemType = buildingPositionsTier3[tier3Index].type
      buildingItemX = buildingPositionsTier3[tier3Index].left - 30
      buildingItemY = buildingPositionsTier3[tier3Index].top - 150
    }
    // console.log('[BUILDING] add ant | buildingItemY:', buildingItemY);  

    // ****** UPDATE LOCATION TO PLACE BUILDING ITEM *******
    let antStepsUpdated = [...antSteps];
    // let antStepsUpdated = removeItem === true ? [...antRemoveSteps] : [...antSteps];
    if (removeItem === true && antStepsUpdated.length > 7) {
      antStepsUpdated[0].ani = 2;
      antStepsUpdated[1].ani = 2;
      antStepsUpdated[2].ani = 2;
      antStepsUpdated[3].ani = 2;
      antStepsUpdated[4].ani = 2;
      antStepsUpdated[5].ani = 3;
      antStepsUpdated[6].ani = 3;
      antStepsUpdated[7].ani = 3;
    }



    // let rightSide = randomIntInRange(0, 1) === 0 ? false : true;
    //console.log('[BUILDING] add ant  rightSide: '+rightSide);
    let antStepUpdated_0 = { ...antStepsUpdated[antStepIndexToPlaceItem_0] };
    antStepUpdated_0.x = buildingItemX
    antStepUpdated_0.y = floorY
    antStepsUpdated[antStepIndexToPlaceItem_0] = antStepUpdated_0;
    let antStepUpdated_1 = { ...antStepsUpdated[antStepIndexToPlaceItem_1] };
    antStepUpdated_1.x = buildingItemX
    antStepUpdated_1.y = floorY
    antStepsUpdated[antStepIndexToPlaceItem_1] = antStepUpdated_1;
    let antStepUpdated_2 = { ...antStepsUpdated[antStepIndexToPlaceItem_2] };
    antStepUpdated_2.x = buildingItemX
    antStepUpdated_2.y = buildingItemY
    antStepsUpdated[antStepIndexToPlaceItem_2] = antStepUpdated_2;
    let antStepUpdated_3 = { ...antStepsUpdated[antStepIndexToPlaceItem_3] };
    antStepUpdated_3.x = buildingItemX
    antStepUpdated_3.y = floorY
    antStepsUpdated[antStepIndexToPlaceItem_3] = antStepUpdated_3;

    let antsUpdated = [...ants];

    // If ROOF, SKIP AND HAVE BIRD AIR LIFT IT IN...
    if (buildingItemType === 6 || buildingItemType === 7 || buildingItemType === 8) {
      // console.log(`[BUILDING] addAnt | roof so skip... buildingItemType: `, buildingItemType);
    } else {
      antsUpdated.push({
        antUID: antUidIndex + 1,
        antSteps: antStepsUpdated,
        rightSide: rightSide,
        aniNum: 2,
        stepIndex: 0,
        startTime: secondsPassed,
        x: -1000,
        y: floorY,
        rotate: 0,
        scale: 1.0,
        transSec: 4.0,
        reverse: false,
        secondsPassedWhenReversed: 0,
        buildingItemType: buildingItemType,
        carriedItemID: buildingItemID,
        carryingItem: removeItem === true ? false : true,
        removeItem: removeItem
      });
    }

    // console.log('[BUILDING] add ant | antsUpdated:', antsUpdated);
    if (tier === 1) {
      let updatedIndex = 0
      if (removeItem === true) {
        if (tier1Index > 0) {
          updatedIndex = tier1Index - 1
          updateTier1Index(updatedIndex)
        }
      } else if (tier1Index < (buildingItemsTier1Status.length - 1)) {
        updatedIndex = tier1Index + 1
        updateTier1Index(updatedIndex)
      }
    } else if (tier === 2) {
      let updatedIndex = 0
      if (removeItem === true) {
        if (tier2Index > 0) {
          updatedIndex = tier2Index - 1
          updateTier2Index(updatedIndex)
        }
      } else if (tier2Index < (buildingItemsTier2Status.length - 1)) {
        updatedIndex = tier2Index + 1
        updateTier2Index(updatedIndex)
      }
    } else if (tier === 3) {
      let updatedIndex = 0
      if (removeItem === true) {
        if (tier3Index > 0) {
          updatedIndex = tier3Index - 1
          updateTier3Index(updatedIndex)
        }
      } else if (tier3Index < (buildingItemsTier3Status.length - 1)) {
        updatedIndex = tier3Index + 1
        updateTier3Index(updatedIndex)
      }
    }
    updateAntUidIndex(antUidIndex + 1)

    // ****** remove old ants *****
    if (ants.length > 0 && ants[0].stepIndex >= (ants[0].antSteps.length - 1)) {
      if (antsUpdated.length > 0) {
        antsUpdated.splice(0, 1);
        // console.log('[BUILDING] add ant | REMOVED ANT AT INDEX 0 updatedAnts:', antsUpdated);
      }
    }

    return antsUpdated;
  }
  const updateAnts = (ants: AntType[], secondsPassed: number, tier: number): AntType[] => {
    // console.log('[BUILDING] update ants... ants: ', ants);    //   // ----- UPDATE ANY
    let modifiedAnts = [...ants];
    if (ants.length > 0) {
      for (var antI = 0; antI < ants.length; antI++) {
        let steps = ants[antI].antSteps;
        // *** UPDATE ANT IF STEP TIME MATCHES ANT LIFETIME steps: i.e. {s:0,x:0,y:600} ---
        const antLifetime = secondsPassed - ants[antI].startTime;
        for (var stepIndex = 0; stepIndex < steps.length; stepIndex++) {
          if (steps[stepIndex].sec === antLifetime) {
            // console.log('[BUILDING] stepIndex: ' + stepIndex + '   antStepIndexToPlaceItem: ' + antStepIndexToPlaceItem_2);
            modifiedAnts[antI].aniNum = steps[stepIndex].ani;
            modifiedAnts[antI].rotate = steps[stepIndex].rot;
            modifiedAnts[antI].x = steps[stepIndex].x;
            modifiedAnts[antI].y = steps[stepIndex].y;
            modifiedAnts[antI].scale = steps[stepIndex].scale;
            modifiedAnts[antI].stepIndex = stepIndex;
            // * ANT PLACES BUILDING ITEM...
            if (stepIndex === antStepIndexToPlaceItem_3) {
              // console.log('[BUILDING] ANT PLACES BUILDING ITEM | ANT ' + modifiedAnts[antI].antUID + ' buildingItemType: ' + modifiedAnts[antI].buildingItemType);
              buildingItemPlaced(modifiedAnts[antI].carriedItemID, modifiedAnts[antI].removeItem)
              // *** CHECK IF DONE AND ALERT BIRD TO ADD ROOF...
              if (tier === 1 && modifiedAnts[antI].carriedItemID === 'T1_BRICK_18') {
                addBird('T1_ROOF', 6, false);
              } else if (tier === 2 && modifiedAnts[antI].carriedItemID === 'T2_BRICK_23') {
                addBird('T2_ROOF', 7, false);
              } else if (tier === 3 && modifiedAnts[antI].carriedItemID === 'T3_BRICK_19') {
                addBird('T3_ROOF', 8, false);
              }
              // * SET ANT TO NOT CARRY THE BUILDING ITME IF NOT REMOVING IT...
              if (modifiedAnts[antI].removeItem === true) {
                modifiedAnts[antI].carryingItem = true;
              } else {
                modifiedAnts[antI].carriedItemID = '';
                modifiedAnts[antI].carryingItem = false;
              }
            }
          }
        }
      }
    }
    return modifiedAnts;
  }

  // ***************** BIRD *****************
  const addBird = (carriedItemID: string, buildingItemType: number, removeItem: boolean) => {
    let rightSide = randomIntInRange(0, 1) === 0 ? false : true;
    // console.log('[BUILDING] add bird | rightSide: ' + rightSide);
    // rightSide = false; // TESTING!!!!!
    setBird({
      rightSide: rightSide,
      aniNum: 2,
      stepIndex: 0,
      startTime: secondsPassed,
      x: -1000,
      y: 0,
      rotate: 0,
      scale: 1.0,
      transSec: 4.0,
      reverse: false,
      secondsPassedWhenReversed: 0,
      buildingItemType: buildingItemType,
      carriedItemID: carriedItemID,
      carryingItem: removeItem === true ? false : true,
      removeItem: removeItem
    });
  }
  const removeBird = () => {
    // console.log('[BUILDING] removeBird');
    setBird(null);
  }

  // * updateBird ----- CALLED EVERY SECOND -----
  const updateBird = () => {
    // console.log('[BUILDING] update bird...');
    // ----- UPDATE BIRD IF ACTIVE -----
    if (bird !== null) {
      let timeToRemoveBird = false;
      // let birdSteps = birdSteps;
      // if (bird.rightSide) {
      //   birdSteps = birdStepsRightSide;
      // }
      if (bird.reverse &&
        bird.stepIndex <= 0) {
        // REVERSE SO last step is zero - remove bird
        //console.log('REVERSE bird last step ');
        timeToRemoveBird = true;
      } else if (bird.stepIndex >= (birdSteps.length - 1)) {
        // last step - remove bird
        //console.log('bird last step ');
        timeToRemoveBird = true;
      } else {
        // --- UPDATE BIRD IF STEP TIME MATCHING bird LIFETIME birdSteps: i.e. {s:0,x:0,y:600} ---
        const birdLifetime = secondsPassed - bird.startTime;
        if (bird.reverse) {
          // console.log('[BUILDING] 1 =======> birdLifetime: '+birdLifetime);
          const secondsPassedSinceTapped = (birdLifetime - bird.secondsPassedWhenReversed);
          // console.log('[BUILDING] 2 =======> secondsPassedSinceTapped: '+secondsPassedSinceTapped);

          let birdLifetimeReversed = 0;
          let birdLifetimeReversedNew = (bird.secondsPassedWhenReversed - secondsPassedSinceTapped);
          // console.log('[BUILDING] 3 =======> birdLifetimeReversedNew: '+birdLifetimeReversedNew);
          if (birdLifetimeReversedNew > 1) {
            birdLifetimeReversed = birdLifetimeReversedNew - 1;
          }

          for (var stepIndex2 = 0; stepIndex2 < birdSteps.length; stepIndex2++) {
            if (birdSteps[stepIndex2].sec === birdLifetimeReversed) {
              // console.log('[BUILDING] stepIndex: '+stepIndex);
              let modifiedBird = Object.assign({}, bird);
              modifiedBird.aniNum = birdSteps[stepIndex2].aniReverse;
              modifiedBird.x = birdSteps[stepIndex2].x;
              modifiedBird.y = birdSteps[stepIndex2].y;
              modifiedBird.stepIndex = stepIndex2;
              setBird(modifiedBird);
            }
          }

        } else {
          for (var stepIndex = 0; stepIndex < birdSteps.length; stepIndex++) {
            if (birdSteps[stepIndex].sec === birdLifetime) {
              // console.log('[BUILDING] stepIndex: ' + stepIndex + '   birdStepIndexToCheckBuildingItem: ' + birdStepIndexToCheckBuildingItem);
              let modifiedBird = Object.assign({}, bird);
              modifiedBird.aniNum = birdSteps[stepIndex].ani;
              modifiedBird.x = birdSteps[stepIndex].x;
              modifiedBird.y = birdSteps[stepIndex].y;
              modifiedBird.stepIndex = stepIndex;
              // ******** BIRD DROP BUILDING ITEM
              // if (bird.rightSide) {
              //   indexToCheck = birdStepIndexToCheckAcornRightSide;
              // }
              if (stepIndex === birdStepIndexToCheckBuildingItem) {
                // console.log('[BUILDING] bird PLACES BUILDING ITEM... ');
                buildingItemPlaced(modifiedBird.carriedItemID, modifiedBird.removeItem)
                // * SET BIRD TO NOT CARRY THE BUILDING ITME IF NOT REMOVING IT...
                if (modifiedBird.removeItem === true) {
                  modifiedBird.carryingItem = true;
                } else {
                  modifiedBird.carriedItemID = '';
                  modifiedBird.carryingItem = false;
                }
              }
              setBird(modifiedBird);
            }
          }
        }
      }

      if (timeToRemoveBird) {
        removeBird();
      }
    }
  }

  return (
    <div className="w-full overflow-hidden">


      {/* BUILDING CONTAINER */}
      <div ref={buildingRef} className="w-full   height-400px flex justify-center width-400px">

        {/* BUILDING ITEMS */}
        <div className="w-full z-10 tierBuilding relative height-273px width-240px">

          {buildingPositionsTier1.map((item, i) => buildingItem(i, item, 1))}
          {buildingPositionsTier2.map((item, i) => buildingItem(i, item, 2))}
          {buildingPositionsTier3.map((item, i) => buildingItem(i, item, 3))}

          {ants.map((ant, i) => {
            return <Ant key={ant.antUID}
              antUID={ant.antUID}
              scale={ant.scale}
              aniNum={ant.aniNum}
              aniNumTotal={ant.antSteps.length}
              // aniNumTotal={ant.rightSide ? antStepsRightSide.length : antSteps.length}
              xPos={ant.x} yPos={ant.y}
              rotate={ant.rotate}
              className="Ant"
              transSec={ant.transSec}
              buildingItemType={ant.buildingItemType}
              tier={tier}
              carriedItemID={ant.carriedItemID}
              carryingItem={ant.carryingItem}
              removeItem={ant.removeItem}
            />
          })}

          {bird && <Bird
            scale={bird.scale}
            aniNum={bird.aniNum}
            aniNumTotal={antSteps.length}
            // aniNumTotal={bird.rightSide ? antStepsRightSide.length : antSteps.length}
            xPos={bird.x} yPos={bird.y}
            className="Bird"
            transSec={bird.transSec}
            buildingItemType={bird.buildingItemType}
            carriedItemID={bird.carriedItemID}
            carryingItem={bird.carryingItem}
            removeItem={bird.removeItem}
          />}


        </div>

        {/* BUILDING BG */}
        <div className="z-0 absolute tierBuilding   height-273px width-240px">
          <svg className="p-0 m-0" xmlns="http://www.w3.org/2000/svg" width="240.76" height="273.06" viewBox="0 0 240.76 273.06">
            <g id="BASE">
              <rect className="cls1" x="1.29" y="209.77" width="238.19" height="62" />
              <rect className="cls1" x="1.29" y="127.56" width="238.19" height="78" />
              <polygon className="cls1" points="239.47 123.36 1.29 123.36 1.29 42.36 119.78 1.36 239.47 42.36 239.47 123.36" />
            </g>
          </svg>
        </div>

      </div>
      
    </div>
  );
}

function randomIntInRange(low: number, high: number) {
  return Math.round(low + (Math.random() * (high - low)));
}

// *------------------------------------------------------*
// {brickPositionsTier1.map((r, indexRow) => {
//   return r.map((c, indexColumn) => {
//     return <div key={indexRow + ' ' + indexColumn} className={[styles.brick, styles.brickStyle1].join(' ')} style={{
//       position: 'absolute',
//       left: r[indexColumn].x,
//       top: r[indexColumn].y,
//     }}>

//     </div>
//   })
// })}

// {brickPositionsTier2.map((r, indexRow) => {
//   return r.map((c, indexColumn) => {
//     return <div key={indexRow + ' ' + indexColumn} className={[styles.brick, styles.brickStyle2].join(' ')} style={{
//       position: 'absolute',
//       left: r[indexColumn].x,
//       top: r[indexColumn].y,
//     }}></div>
//   })
// })}

// {brickPositionsTier3.map((r, indexRow) => {
//   return r.map((c, indexColumn) => {
//     return <div key={indexRow + ' ' + indexColumn} className={[styles.brick, styles.brickStyle3].join(' ')} style={{
//       position: 'absolute',
//       left: r[indexColumn].x,
//       top: r[indexColumn].y,
//     }}></div>
//   })
// })}

  // * returns a multidimensional array of brick positions
  // const buildingLevelBrickPositions = (
  //   tier: number, x: number = 0, y: number = 0,
  //   rows: number, columns: number,
  //   width: number, height: number): BrickPosition[][] => {

  //   let positions: BrickPosition[][] = [];
  //   let brickWidth = width / columns;
  //   let brickHeight = height / rows;
  //   let indent = 0;
  //   if (tier === 2) {
  //     indent = brickWidth/4;
  //   }
  //   if (tier === 3) {
  //     indent = (brickWidth/3);
  //   }
  //   for (let r = 0; r < rows; r++) {
  //     let rowPositions: BrickPosition[] = []
  //     for (let c = 0; c < columns; c++) {
  //       let pos: BrickPosition = {
  //         x: indent + x + (brickWidth * c),
  //         y: y + brickHeight + (brickHeight * r),
  //       };
  //       rowPositions.push(pos);
  //     }
  //     positions.push(rowPositions);
  //   }
  //   return positions;
  // }
  // let buildingWidth = 600;
  // // let buildingWidth = buildingRef.current?.offsetWidth;
  // // if (buildingWidth === undefined) {
  // //   buildingWidth = 800;
  // // }
  // let buildingHeight = 400;
  // // let buildingHeight = buildingRef.current?.offsetHeight;
  // // if (buildingHeight === undefined) {
  // //   buildingHeight = 400;
  // // }

  // const buildingTierWidth = buildingWidth;
  // const buildingTierHeight = buildingHeight / 4;

  // let rowsTier1 = 3
  // let columnsTier1 = 12
  // let brickHeightTier1 = (buildingTierHeight / rowsTier1)
  // let brickPositionsTier1 = buildingLevelBrickPositions(1,
  //   0, buildingHeight - buildingTierHeight - brickHeightTier1,
  //   rowsTier1, columnsTier1, buildingTierWidth, buildingTierHeight)

  // let rowsTier2 = 3
  // let columnsTier2 = 8
  // let brickHeightTier2 = (buildingTierHeight / rowsTier2)
  // let brickPositionsTier2 = buildingLevelBrickPositions(2,
  //   0, buildingHeight - (buildingTierHeight * 2) - brickHeightTier2,
  //   rowsTier2, columnsTier2, buildingTierWidth, buildingTierHeight)

  // let rowsTier3 = 3
  // let columnsTier3 = 6
  // let brickHeightTier3 = (buildingTierHeight / rowsTier3)
  // let brickPositionsTier3 = buildingLevelBrickPositions(3,
  //   0, buildingHeight - (buildingTierHeight * 3) - brickHeightTier3,
//   rowsTier3, columnsTier3, buildingTierWidth, buildingTierHeight)



// {/* <div className="flex justify-center align-middle">
// <div className="px-10 text-2xl bold">{tier} Tier{tier > 1 ? 's' : ''} </div>
// </div> */}

// {/* <div className="w-full flex justify-center align-middle p-5">
// <button
//   className={`button rounded-md px-5 py-2 bg-gray-200 mx-5
//   hover:bg-gray-500 
//    ${tier > 1 ? '' : 'opacity-20'}`}
//   disabled={tier <= 1 ? true : false}
//   aria-label="Decrement value"
//   onClick={() => dispatch(decrement())}
// >
//   -
// </button>
// <button
//   className={`button rounded-md px-5 py-2 bg-gray-200  mx-5
//   hover:bg-gray-500 
//      ${tier < 3 ? '' : 'opacity-20'}`}
//   disabled={tier >= 3 ? true : false}
//   aria-label="Increment value"
//   onClick={() => dispatch(increment())}
// >
//   +
// </button>
// </div>
// <div className="w-full flex justify-center align-middle p-5">
// <button
//   className={`button rounded-md px-5 py-2 bg-gray-200  mx-5
//   hover:bg-gray-500 
//      ${paused ? 'bg-green-500' : 'bg-red-500'}`}
//   disabled={tier >= 3 ? true : false}
//   aria-label="pause toggle"
//   onClick={() => setPaused(!paused)}
// >
//   {paused ? 'Resume' : 'Pause'}
// </button>
// </div> */}