export enum BuildingItemType {
  Brick_tier1 = 0,
  Brick_tier2 = 1,
  Brick_tier3 = 2,
  Window_tier1 = 3,
  Window_tier2 = 4,
  Window_tier3 = 5,
  Roof_tier1 = 6,
  Roof_tier2 = 7,
  Roof_tier3 = 8,
  Door_tier1 = 9,
}

export type BuildingItemPositionType = {
  id: string;
  type: BuildingItemType;
  //   rotation: number;
  //   state: BuildingItemState;
  top: number;
  left: number;
  width: number;
  height: number;
};

// *********** TIER 1 ***************
export const buildingPositionsTier1: BuildingItemPositionType[] = [
  {
    id: "T1_BRICK_01",
    type: 0,
    left: 2,
    top: 256,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_02",
    type: 0,
    left: 2,
    top: 241,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_03",
    type: 0,
    left: 2,
    top: 225,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_04",
    type: 0,
    left: 2,
    top: 210,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_05",
    type: 0,
    left: 35,
    top: 256,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_06",
    type: 0,
    left: 69,
    top: 256,
    width: 32,
    height: 14,
  },
  {
    id: "T1_WINDOW1",
    type: 3,
    left: 36,
    top: 210,
    width: 31,
    height: 45,
  },
  {
    id: "T1_BRICK_07",
    type: 0,
    left: 69,
    top: 241,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_08",
    type: 0,
    left: 69,
    top: 225,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_09",
    type: 0,
    left: 69,
    top: 210,
    width: 32,
    height: 14,
  },
  {
    id: "T1_DOOR",
    type: 9,
    left: 103,
    top: 209,
    width: 33,
    height: 61,
  },
  {
    id: "T1_BRICK_10",
    type: 0,
    left: 138,
    top: 256,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_11",
    type: 0,
    left: 138,
    top: 241,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_12",
    type: 0,
    left: 138,
    top: 225,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_13",
    type: 0,
    left: 138,
    top: 210,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_14",
    type: 0,
    left: 172,
    top: 256,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_15",
    type: 0,
    left: 205,
    top: 256,
    width: 32,
    height: 14,
  },
  {
    id: "T1_WINDOW2",
    type: 3,
    left: 172,
    top: 210,
    width: 31,
    height: 45,
  },
  {
    id: "T1_BRICK_16",
    type: 0,
    left: 205,
    top: 241,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_17",
    type: 0,
    left: 205,
    top: 225,
    width: 32,
    height: 14,
  },
  {
    id: "T1_BRICK_18",
    type: 0,
    left: 205,
    top: 210,
    width: 32,
    height: 14,
  },
  {
    id: "T1_ROOF",
    type: 6,
    left: 2,
    top: 165,
    width: 237,
    height: 43,
  },
];

// *********** TIER 2 ***************
export const buildingPositionsTier2: BuildingItemPositionType[] = [
  {
    id: "T2_BRICK_01",
    type: 1,
    left: 2,
    top: 189,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_02",
    type: 1,
    left: 2,
    top: 173,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_03",
    type: 1,
    left: 2,
    top: 158,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_04",
    type: 1,
    left: 2,
    top: 142,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_05",
    type: 1,
    left: 1,
    top: 127,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_06",
    type: 1,
    left: 36,
    top: 189,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_07",
    type: 1,
    left: 35,
    top: 174,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_08",
    type: 1,
    left: 35,
    top: 158,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_09",
    type: 1,
    left: 35,
    top: 143,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_10",
    type: 1,
    left: 35,
    top: 127,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_11",
    type: 1,
    left: 70,
    top: 189,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_12",
    type: 1,
    left: 104,
    top: 189,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_13",
    type: 1,
    left: 139,
    top: 189,
    width: 32,
    height: 14,
  },
  {
    id: "T2_WINDOW_01",
    type: 4,
    left: 69,
    top: 127,
    width: 101,
    height: 61,
  },
  {
    id: "T2_BRICK_14",
    type: 1,
    left: 173,
    top: 189,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_15",
    type: 1,
    left: 173,
    top: 173,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_16",
    type: 1,
    left: 173,
    top: 158,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_17",
    type: 1,
    left: 172,
    top: 143,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_18",
    type: 1,
    left: 172,
    top: 127,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_19",
    type: 1,
    left: 207,
    top: 189,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_20",
    type: 1,
    left: 207,
    top: 173,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_21",
    type: 1,
    left: 206,
    top: 158,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_22",
    type: 1,
    left: 206,
    top: 143,
    width: 32,
    height: 14,
  },
  {
    id: "T2_BRICK_23",
    type: 1,
    left: 206,
    top: 127,
    width: 32,
    height: 14,
  },
  {
    id: "T2_ROOF",
    type: 7,
    left: 2,
    top: 82,
    width: 237,
    height: 43,
  },
];

// *********** TIER 3 ***************
export const buildingPositionsTier3: BuildingItemPositionType[] = [
  {
    id: "T3_BRICK_01",
    type: 2,
    left: 1,
    top: 107,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_02",
    type: 2,
    left: 1,
    top: 92,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_03",
    type: 2,
    left: 1,
    top: 76,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_04",
    type: 2,
    left: 1,
    top: 61,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_05",
    type: 2,
    left: 1,
    top: 45,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_06",
    type: 2,
    left: 35,
    top: 107,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_07",
    type: 2,
    left: 69,
    top: 107,
    width: 32,
    height: 14,
  },
  {
    id: "T3_WINDOW_01",
    type: 5,
    left: 35,
    top: 45,
    width: 66,
    height: 61,
  },
  {
    id: "T3_BRICK_08",
    type: 2,
    left: 104,
    top: 107,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_09",
    type: 2,
    left: 104,
    top: 92,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_10",
    type: 2,
    left: 104,
    top: 76,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_11",
    type: 2,
    left: 104,
    top: 61,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_12",
    type: 2,
    left: 104,
    top: 45,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_13",
    type: 2,
    left: 138,
    top: 107,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_14",
    type: 2,
    left: 172,
    top: 107,
    width: 32,
    height: 14,
  },
  {
    id: "T3_WINDOW_02",
    type: 5,
    left: 138,
    top: 45,
    width: 66,
    height: 61,
  },
  {
    id: "T3_BRICK_15",
    type: 2,
    left: 206,
    top: 107,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_16",
    type: 2,
    left: 206,
    top: 92,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_17",
    type: 2,
    left: 206,
    top: 76,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_18",
    type: 2,
    left: 206,
    top: 61,
    width: 32,
    height: 14,
  },
  {
    id: "T3_BRICK_19",
    type: 2,
    left: 206,
    top: 45,
    width: 32,
    height: 14,
  },
  {
    id: "T3_ROOF",
    type: 8,
    left: 2,
    top: 1,
    width: 237,
    height: 43,
  },
];
