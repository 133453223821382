import React, { useState, useEffect, useRef, useCallback } from 'react';

import { gsap } from "gsap";
import lottie from "lottie-web"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import {
  RevealSlideUp,
  RevealSlideRight,
  RevealFlipUp,
  RevealFlipRight,
  RevealFade,
} from "../utils/reveal"

import { Building } from '../components/building/Building';
import animationLogo from "../../content/assets/lottie/logo_white.json"

import { TierType } from '../components/building/Building'
import TierButton from '../components/building/TierButton';

import pricingSectionBgImage from '../../content/assets/building/pricing_page_section_bg.png'
import pricingSectionWallMiddleImage from '../../content/assets/building/wall_middle.png'
import starsSVG from '../../content/assets/stars.svg'
import tierLightUnselectedImage from '../../content/assets/building/tier_light_unselected.png'
import tier1LightSelectedImage from '../../content/assets/building/tier1_light_selected.png'
import tier2LightSelectedImage from '../../content/assets/building/tier2_light_selected.png'
import tier3LightSelectedImage from '../../content/assets/building/tier3_light_selected.png'
import photoMeImage from '../../content/assets/photo_abc.png'

import Layout from "../components/layout"
import SEO from "../components/seo"


function ServicesPage() {
  const [paused, setPaused] = useState(true)
  const pause = useCallback(() => {
    if (paused === false) {
      setPaused(true)
    }
  }, [paused])
  const unPause = useCallback(() => {
    if (paused === true) {
      setPaused(false)
    }
  }, [paused])

  const [tier, seTier] = useState<TierType>(1)

  // ********* TIER **********
  const onSetTier = useCallback((tierNum: TierType) => {
    // console.log('[APP] onSetTier | tierNum:', tierNum);
    if (tierNum === 1 || tierNum === 2 || tierNum === 3) {
      if (tier !== tierNum) {
        seTier(tierNum as TierType)
      }
    }
  }, [tier])

  const onIncreaseTier = useCallback(() => {
    let newTier: TierType = tier;
    if (tier < 3) {
      newTier += 1
      seTier(newTier as TierType)
    }
  }, [tier])
  const onDecreaseTier = useCallback(() => {
    let newTier: TierType = tier;
    if (tier > 1) {
      newTier -= 1
      seTier(newTier as TierType)
    }
  }, [tier])


  // ************ GSAP **************
  const onStartBuilding = () => {
    // console.log('onStartBuilding...')
    unPause()
  }

  const boxRef = useRef<HTMLInputElement>(null);
  // const box = gsap.utils.selector(boxRef);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.to("#hangerDoor5", {
      x: 350,
      opacity: 1,
      duration: 5,
      scrollTrigger: {
        trigger: "#hangerDoor5",
        markers: false,
        // start: "top center",
        // end: "bottom 80px",
        start: "top bottom",
        end: "bottom center",
        scrub: true
      },
      onStart: onStartBuilding
    });
    gsap.to("#hangerDoor4", {
      x: 280,
      opacity: 1,
      duration: 5,
      scrollTrigger: {
        trigger: "#hangerDoor4",
        markers: false,
        start: "top bottom",
        end: "bottom center",
        scrub: true
      }
    });
    gsap.to("#hangerDoor3", {
      x: 200,
      opacity: 1,
      duration: 5,
      scrollTrigger: {
        trigger: "#hangerDoor3",
        markers: false,
        start: "top bottom",
        end: "bottom center",
        scrub: true
      }
    });
    gsap.to("#hangerDoor2", {
      x: 120,
      opacity: 1,
      duration: 5,
      scrollTrigger: {
        trigger: "#hangerDoor2",
        markers: false,
        start: "top bottom",
        end: "bottom center",
        scrub: true
      }
    });
    gsap.to("#hangerDoor1", {
      x: 35,
      opacity: 1,
      duration: 5,
      scrollTrigger: {
        trigger: "#hangerDoor1",
        markers: false,
        start: "top bottom",
        end: "bottom center",
        scrub: true
      }
    });
  }, []);

  // *********** LOTTIE LOGO ***********
  // let timerShowLogo: any
  let lottieLogoContainer = useRef<HTMLInputElement>(null);
  const [logoAnimationComplete, setLogoAnimationComplete] = useState(false)
  const logoAnimationCompleted = () => {
    setLogoAnimationComplete(true)
  }
  let logoAnim: any = useRef(null)
  useEffect(() => {
    if (
      lottieLogoContainer.current !== undefined &&
      lottieLogoContainer.current !== null) {
      logoAnim.current = lottie.loadAnimation({
        container: lottieLogoContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: animationLogo,
      })
      logoAnim.current.addEventListener("complete", logoAnimationCompleted)
    }
    // if (!timerShowLogo) {
    //   timerShowLogo = setTimeout(() => {
    //     if (logoAnim.current) {
    //       logoAnim.current.play()
    //     }
    //   }, 2000)
    // }
    return () => {
      logoAnim.current?.removeEventListener()
      logoAnim.current?.destroy()
      // if (timerShowLogo) {
      //   clearTimeout(timerShowLogo)
      // }
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="ABC Interactive LLC Providing quality development services since 2005 | Services"
      />
      <section className="relative height-screen-50 mt-20
       flex align-middle justify-center bg-black 
       font-family-opensans">
        <div className="z-20 text-white bold text-center">
          <h1 className="py-10 text-xl md:text-4xl p-5 ">Welcome to ABC Interactive LLC! </h1>
          <RevealFade delay={1000}>
            <h2 className="uppercase text-slate-400  text-lg md:text-xl p-5 transition delay-1000">Web Application Frontend Development Services </h2>
          </RevealFade>
        </div>
        <div
          className={`z-20
              h-full w-full
              absolute top-0            
              transform z-50 pointer-events-none 
              transition ease-in-out         
              origin-center 
              inline-flex justify-center            
               ${logoAnimationComplete
              ? "rotate-90 scale-50 duration-500"
              : "delay-1000 scale-125 duration-2000"
            }
                `}
        >
          <div
            ref={lottieLogoContainer}
            className={`
               h-full origin-center  
                 
               ${logoAnimationComplete
                ? "transform delay-1000 opacity-0 duration-500 margin-left-50"
                : ""
              }           
              `}
          />
        </div>
        <div className="w-full absolute bottom-0 py-40 text-white  bg-gradient-to-t to-black from-blue-200">
        </div>
        <div className="z-1 h-screen absolute top-0">

          <img src={starsSVG} className="block " alt="" width="1000" height="2000" />
        </div>
      </section>
      <section className="w-full relative grid grid-cols-1 md:grid-cols-3 gap-0 bg-blue-200 overflow-hidden"
        style={{
          backgroundImage: "url(" + pricingSectionWallMiddleImage + ")", backgroundPositionY: 'top',
          backgroundSize: 'contain'
        }}>
        <div className="w-full flex justify-center minWidthBuilding col-span-2">
          <div className="relative minWidthBuilding">
            {/* <img className='z-0 absolute top-0 ' src={pricingSectionBgImage} alt="" width='400' height='331' /> */}
            <div className='z-40 absolute top-0  w-full z '>
              <PricingSectionHangerImageSVG />
            </div>
            <div id="hangerDoor1" className='hangerDoor zPosition_28 absoluteRight_28 '>
              <PricingSectionHangerDoorImageSVG />
            </div>
            <div id="hangerDoor2" className='hangerDoor zPosition_26 absoluteRight_26'>
              <PricingSectionHangerDoorImageSVG />
            </div>
            <div id="hangerDoor3" className='hangerDoor zPosition_24 absoluteRight_24'>
              <PricingSectionHangerDoorImageSVG />
            </div>
            <div id="hangerDoor4" className='hangerDoor zPosition_22 absoluteRight_22'>
              <PricingSectionHangerDoorImageSVG />
            </div>
            <div id="hangerDoor5" className='hangerDoor zPosition_20 absoluteRight_20'>
              <PricingSectionHangerDoorImageSVG />
            </div>
            <div className='w-full z-10 absolute top-24 left-10 '>
              <Building _paused={paused} _tier={tier} />
            </div>
            <img className='pricingSectionLightTier1' src={tier >= 1 ? tier1LightSelectedImage : tierLightUnselectedImage} alt="" width='100' height='100' />
            <img className='pricingSectionLightTier2' src={tier >= 2 ? tier2LightSelectedImage : tierLightUnselectedImage} alt="" width='100' height='100' />
            <img className='pricingSectionLightTier3' src={tier === 3 ? tier3LightSelectedImage : tierLightUnselectedImage} alt="" width='100' height='100' />
            <img className='pricingSectionBg' src={pricingSectionBgImage} alt="" width='484' height='395' />
          </div>
        </div>
        <div className="pt-2 pb-5 md:pt-20 md:pb-0">

          {/* BUTTONS */}
          {/* <div className="flex justify-center align-middle p-5"> */}
          <div className="p-1.5 md:p-2 mx-2 md:ml-0 md:mr-2 sm:w-auto  font-family-opensans bg-white border rounded-lg 
          bg-gray-800 border-gray-700 shadow-md shadow-black">
            <div className="w-full flex justify-center space-x-2 sm:space-y-0 sm:flex
            rounded-md bg-slate-600">
              <TierButton selected={tier === 1} tier={1} onSetTier={onSetTier}>
                <h3 className="uppercase bold text-xl">Tier 1</h3>
              </TierButton>
              <TierButton selected={tier === 2} tier={2} onSetTier={onSetTier}>
                <h3 className="uppercase bold text-xl">Tier 2</h3>
              </TierButton>
              <TierButton selected={tier === 3} tier={3} onSetTier={onSetTier}>
                <h3 className="uppercase bold text-xl">Tier 3</h3>
              </TierButton>

            </div>
            {/* LABEL */}
            <div className={`text-white px-2 py-2 md:pt-1 md:pb-2 font-family-opensans`}>
              {tier === 1 && <div className="">
                <h4 className="text-lg font-medium text-gray-100">Landing Page &#40;SPA&#41; </h4>
                <p className="text-sm mt-1 text-gray-300">Responsive React JS single page website, tailored sections, Lottie animations</p>
                <div className="text-base md:text-sm mt-2 space-y-1">
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">1 page </span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Design customization</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Responsive design</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">SEO</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <div className="mx-4 text-gray-300">
                      <a className="flex justify-start" href="https://nextjs.org/" target="_blank" rel="noreferrer">{<NextIcon />} <span className="pl-2">NEXT web app</span></a>
                    </div>
                  </div>
                </div>
              </div>}
              {tier === 2 && <div className="">
                <h4 className="text-lg font-medium text-gray-100">Small Web Application</h4>
                <p className="text-sm mt-1 text-gray-300">Responsive React JS website, tailored sections, Lottie animations, navigation</p>
                <div className="text-base md:text-sm mt-2 space-y-1">
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">2-5 pages</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Design customization</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Responsive design</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">SEO</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Navigatiion &amp; page routing</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <div className="mx-4 text-gray-300">
                      <a className="flex justify-start" href="https://nextjs.org/" target="_blank" rel="noreferrer">{<NextIcon />} <span className="pl-2">NEXT web app</span></a>
                    </div>
                  </div>
                </div>
              </div>}
              {tier === 3 && <div className="">
                <h4 className="text-lg font-medium text-gray-100">Large Web Application</h4>
                <p className="text-sm mt-1 text-gray-300">Responsive React JS website, larger,  &amp; RESTful content</p>
                <div className="text-base md:text-sm mt-2 space-y-1">
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">6+ pages</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Design customization</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Responsive design</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">SEO</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <span className="mx-4 text-gray-300">Navigatiion &amp; page routing</span>
                  </div>
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                    <div className="mx-4  text-gray-300">
                      <a className="flex justify-start" href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">{<GatsbyIcon />} <span className="pl-1">Gatsby RESTful web app</span></a>
                    </div>
                  </div>
                </div>
              </div>}

            </div>
          </div>
        </div>
      </section>
      <section className="relative p-50 flex align-middle justify-left py-5 bg-gray-800 ">
        {/* <div className="container mx-auto text-white lg:flex">
          <div className="w-full lg:w-1/2 px-6 py-10">
            <h2 className="my-10 text-xl ">My name is Andrew and I have experience designing, building, launching, and maintaining quality websites since 2005. </h2>
            <p className="my-2">I will build you a responsive Progressive Web Application &#40;PWA&#41; with a Static Site Generator for excellent performance, SEO, and security!</p>
            <p className="my-2">Also, I enjoy creating React Native apps for all platforms and interactive animated content to help engage visitors.</p>
          </div>
          <div className="w-full lg:w-1/2 px-6 py-10">
            <div className="flex items-center mt-8 -mx-2">
              <img className="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300 dark:ring-gray-700" width="150" height="150" src={photoMeImage} alt="" />
              <div className="mx-2">
                <h3 className="font-semibold text-white">Andrew Chew</h3>
                <span className="text-sm text-gray-500">CEO ABC Interactive LLC</span>
              </div>
            </div>
          </div>
        </div> */}

      </section>
      <section id="contact" className="relative p-50 flex align-middle justify-left py-5 bg-gray-200 ">
        <div className="container px-6 py-10 mx-auto">
          <RevealSlideUp>
            <h3 className="text-3xl font-semibold text-center text-gray-800  font-family-opensans  capitalize lg:text-4xl dark:text-white">
              Contact ABC Interactive LLC
            </h3>
          </RevealSlideUp>
          <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3">
            <RevealFade delay={300}>
              <div
                className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 
            transform rounded-md dark:text-gray-200"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" />
                </svg>

                <span className="mt-2">USA</span>
              </div>
            </RevealFade>

            <RevealFade delay={600}>
              <div
                className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 
            transform rounded-md dark:text-gray-200"
              >
                {/* <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>

                <span className="mt-2"></span> */}
              </div>
            </RevealFade>

            <RevealFade delay={900}>
              <a
                href="mailto:info@abc-interactive.com"
                className="
            flex flex-col items-center px-4 py-3 text-gray-700 transition duration-200 
            transform rounded-md dark:text-gray-200 bg-blue-200 hover:bg-blue-500 dark:hover:bg-blue-200
            hover:-translate-y-1 hover:scale-110
            "
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>

                <span className="mt-2">info@abc-interactive.com</span>
              </a>
            </RevealFade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPage


// **** HANGER SVG THAT LAYS OVER BUILDING | ORIG SIZE 900x743.5, set to 400x331  484x400
const PricingSectionHangerImageSVG = () => <svg id="COLUMN_LEFT" data-name="COLUMN LEFT" xmlns="http://www.w3.org/2000/svg"
  width="484" height="400" viewBox="0 0 900 743.55">
  <defs>
    <linearGradient id="linear-gradient" x1="124.1" y1="126.75" x2="111.2" y2="67.61" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#b3b3b3" />
      <stop offset="1" stopColor="#666" />
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="342.21" y1="110.89" x2="332.54" y2="27.02" />
    <linearGradient id="linear-gradient-3" x1="565.89" y1="113.44" x2="550.83" y2="13.44" />
    <linearGradient id="linear-gradient-4" x1="784.09" y1="108.66" x2="773.34" y2="34.47" />
  </defs>
  <g id="HANGER">
    <g id="SKY">
      <rect className="cls2" width="900" height="138.98" />
    </g>
    <g id="HANGER_DOOR_BIG_copy" data-name="HANGER DOOR BIG copy">
      <path className="cls3" d="M899.53,728.23V81.23S756.64,4.46,450.01,4.46,.48,81.23,.48,81.23V728.23h-.48v15.32H900v-15.32h-.47Zm-806.21,8V151.75H806.72V736.23H93.32Z" />
    </g>
    <g id="WINDOWS">
      <path className="cls1" d="M217.19,131.77V40.67C105.19,57.57,39.26,83.2,13.32,94.95v36.82H217.19Z" />
      <path className="cls1" d="M440.37,131.77V24.49c-79.32,.4-147.05,5.73-203.87,13.42v93.86h203.87Z" />
      <path className="cls1" d="M663.55,131.77V38.31c-57.12-7.89-124.96-13.4-203.87-13.82v107.28h203.87Z" />
      <path className="cls1" d="M886.72,131.77v-36.58c-26.25-11.53-93.19-37.04-203.87-54.06v90.64h203.87Z" />
    </g>
  </g>
</svg>

const PricingSectionHangerDoorImageSVG = () => <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
  width="99" height="350" viewBox="0 0 166.33 592.48">
  <rect id="OUTER_PANEL" data-name="OUTER PANEL" className="cls22" width="166.33" height="592.48" />
  <rect id="BEVEL" className="cls21" x="10.83" y="10.76" width="145.01" height="570.96" />
  <rect id="INNER_PANEL" data-name="INNER PANEL" className="cls23" x="13.07" y="13.05" width="139.36" height="567.58" />
  <rect className="cls24" x="28.17" y="448.96" width="106.67" height="26.2" />
</svg>

const NextIcon = () => <svg width="20" height="20" viewBox=".5 -.2 1023 1024.1" xmlns="http://www.w3.org/2000/svg">
  <path d="m478.5.6c-2.2.2-9.2.9-15.5 1.4-145.3 13.1-281.4 91.5-367.6 212-48 67-78.7 143-90.3 223.5-4.1 28.1-4.6 36.4-4.6 74.5s.5 46.4 4.6 74.5c27.8 192.1 164.5 353.5 349.9 413.3 33.2 10.7 68.2 18 108 22.4 15.5 1.7 82.5 1.7 98 0 68.7-7.6 126.9-24.6 184.3-53.9 8.8-4.5 10.5-5.7 9.3-6.7-.8-.6-38.3-50.9-83.3-111.7l-81.8-110.5-102.5-151.7c-56.4-83.4-102.8-151.6-103.2-151.6-.4-.1-.8 67.3-1 149.6-.3 144.1-.4 149.9-2.2 153.3-2.6 4.9-4.6 6.9-8.8 9.1-3.2 1.6-6 1.9-21.1 1.9h-17.3l-4.6-2.9c-3-1.9-5.2-4.4-6.7-7.3l-2.1-4.5.2-200.5.3-200.6 3.1-3.9c1.6-2.1 5-4.8 7.4-6.1 4.1-2 5.7-2.2 23-2.2 20.4 0 23.8.8 29.1 6.6 1.5 1.6 57 85.2 123.4 185.9s157.2 238.2 201.8 305.7l81 122.7 4.1-2.7c36.3-23.6 74.7-57.2 105.1-92.2 64.7-74.3 106.4-164.9 120.4-261.5 4.1-28.1 4.6-36.4 4.6-74.5s-.5-46.4-4.6-74.5c-27.8-192.1-164.5-353.5-349.9-413.3-32.7-10.6-67.5-17.9-106.5-22.3-9.6-1-75.7-2.1-84-1.3zm209.4 309.4c4.8 2.4 8.7 7 10.1 11.8.8 2.6 1 58.2.8 183.5l-.3 179.8-31.7-48.6-31.8-48.6v-130.7c0-84.5.4-132 1-134.3 1.6-5.6 5.1-10 9.9-12.6 4.1-2.1 5.6-2.3 21.3-2.3 14.8 0 17.4.2 20.7 2z" fill="#FFF" />
  <path d="m784.3 945.1c-3.5 2.2-4.6 3.7-1.5 2 2.2-1.3 5.8-4 5.2-4.1-.3 0-2 1-3.7 2.1zm-6.9 4.5c-1.8 1.4-1.8 1.5.4.4 1.2-.6 2.2-1.3 2.2-1.5 0-.8-.5-.6-2.6 1.1zm-5 3c-1.8 1.4-1.8 1.5.4.4 1.2-.6 2.2-1.3 2.2-1.5 0-.8-.5-.6-2.6 1.1zm-5 3c-1.8 1.4-1.8 1.5.4.4 1.2-.6 2.2-1.3 2.2-1.5 0-.8-.5-.6-2.6 1.1zm-7.6 4c-3.8 2-3.6 2.8.2.9 1.7-.9 3-1.8 3-2 0-.7-.1-.6-3.2 1.1z" /></svg>
const GatsbyIcon = () => <svg width="22" height="22" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" >
  <path d="M25 14h-7v2h4.8c-.7 3-2.9 5.5-5.8 6.5L5.5 11c1.2-3.5 4.6-6 8.5-6 3 0 5.7 1.5 7.4 3.8l1.5-1.3C20.9 4.8 17.7 3 14 3 8.8 3 4.4 6.7 3.3 11.6l13.2 13.2C21.3 23.6 25 19.2 25 14zm-22 .1c0 2.8 1.1 5.5 3.2 7.6s4.9 3.2 7.6 3.2z" fill="#fff" />
  <path d="M14 0C6.3 0 0 6.3 0 14s6.3 14 14 14 14-6.3 14-14S21.7 0 14 0zM6.2 21.8C4.1 19.7 3 16.9 3 14.2L13.9 25c-2.8-.1-5.6-1.1-7.7-3.2zm10.2 2.9L3.3 11.6C4.4 6.7 8.8 3 14 3c3.7 0 6.9 1.8 8.9 4.5l-1.5 1.3C19.7 6.5 17 5 14 5c-3.9 0-7.2 2.5-8.5 6L17 22.5c2.9-1 5.1-3.5 5.8-6.5H18v-2h7c0 5.2-3.7 9.6-8.6 10.7z" fill="#639" />
</svg>


// ----- PAUSE BUTTON -----
{/* <button
  className={`button rounded-md px-2 py-1 bg-gray-200  mx-5 h-8 text-xs
  hover:bg-gray-500 
      ${paused ? 'bg-green-500' : 'bg-red-500'}`}
  disabled={tier >= 3 ? true : false}
  aria-label="pause toggle"
  onClick={paused ? unPause : pause}
>
  {paused ? 'Resume' : 'Pause'}
</button> */}

