import React, { Component } from "react";
import Spritesheet from "react-responsive-spritesheet";

// import { BuildingItemType } from "./buildingPositions";

import aniImageFly from "../../../content/assets/building/bird_fly_spritesheet.png";
import aniImageFlyLTR from "../../../content/assets/building/bird_fly_LTR_spritesheet.png";
import aniImageSettle from "../../../content/assets/building/bird_settle_spritesheet.png";
import aniImagePick from "../../../content/assets/building/bird_pick_spritesheet.png";
import BuildingItem from "./BuildingItem";

class Bird extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frame: 0,
    };

    this.frameHeight = 256;
    this.frameWidth = 256;
    this.frameSteps = 8;
    this.frameStepsSettle = 3;
    this.frameStepsPick = 2;
  }
  componentDidMount() {
    this.setState({ frame: 0 });
  }
  componentWillUnmount() {}

  getWrapperStyles() {
    // console.log('[BIRD] rotate: '+this.props.rotate);
    let opacity = 1.0;
    return {
      zIndex: 5,
      width: 128,
      height: 128,
      opacity: opacity,
      transform: `scale(${this.props.scale})`,
    };
  }

  render() {
    let imageToUse = aniImageFly;
    let aniFrameSteps = this.frameSteps;
    // let loopAnimation = true;
    let acornCarryTop = 65;
    let acornCarryLeft = 30;
    if (this.props.aniNum > 0) {
      if (this.props.aniNum === 1) {
        // fly
        imageToUse = aniImageFly;
        // loopAnimation = true;
      } else if (this.props.aniNum === 2) {
        // fly LTR
        imageToUse = aniImageFlyLTR;
        // loopAnimation = true;

        acornCarryTop = 65;
        acornCarryLeft = 70;
      } else if (this.props.aniNum === 3) {
        // settle on sign getting ready to pick!
        aniFrameSteps = this.frameStepsSettle;
        imageToUse = aniImageSettle;
        // loopAnimation = true;
      } else if (this.props.aniNum === 4) {
        // pick acorn
        aniFrameSteps = this.frameStepsPick;
        imageToUse = aniImagePick;
        // loopAnimation = true;
      }
    }

    // **** DETERMINE IF BUILDING ITEM IS SHOW OR NOT ****
    let showItemCarried = false;
    if (this.props.carryingItem === true && this.props.carryiedItemID !== "") {
      // ******* IS CARRYING...
      showItemCarried = true
    }

    // console.log('[BIRD]  aniFrameSteps: '+aniFrameSteps+' image: '+imageToUse);
    // console.log('[BIRD]  this.props.buildingItemType: '+this.props.buildingItemType);
    return (
      <div
        className=""
        style={{
          position: "absolute",
          left: this.props.xPos,
          top: this.props.yPos,
          width: 128,
          height: 128,
          zIndex: 500,
          transition: `all ${this.props.transSec}s ease-out`,
        }}
      >
        <div
          style={{
            position: "relative",
            left: acornCarryLeft,
            top: acornCarryTop,
            width: 64,
            height: 64,
            zIndex: 510,
          }}
        >
          <div className="flex justify-center">
            {this.props.buildingItemType !== undefined &&
              showItemCarried === true && (
                <BuildingItem
                  tier={this.props.tier}
                  carried={true}
                  buildingItemType={this.props.buildingItemType}
                />
              )}
          </div>
        </div>
        <div style={this.getWrapperStyles()} className="absolute left-0 top-0">
          <Spritesheet
            image={imageToUse}
            widthFrame={this.frameWidth}
            heightFrame={this.frameHeight}
            steps={aniFrameSteps}
            fps={12}
            loop={true}
          />
        </div>
      </div>
    );
  }
}
export default Bird;
