import * as React from 'react';


import { TierType } from './Building'

export type TierButtonProps = {
    children: React.ReactNode;
    selected: boolean;
    tier: TierType;
    onSetTier: (tierNum: TierType) => void;
}

export default function TierButton({ children, selected, tier, onSetTier }: TierButtonProps) {
    // const asdsa = () => {  }
    return (
        <div  className={` w-full py-1  px-1  col-span-1 
        `}>
            <button
                onClick={() => onSetTier(tier)}
                className={`w-full  
        focus:ring focus:ring-blue-200 focus:ring-opacity-40
        first-letter:transition-colors duration-200 
        transform rounded-md focus:outline-none               
       hover:bg-blue-400 hover:text-white
       ${selected ? ' bg-black text-white' : ' bg-blue-300 focus:bg-blue-500'}  
        `}
            >
                {children}
            </button>
            {selected && <div className="bg-gray-900 p-1 mt-1 w-full rounded-md"></div>}
        </div>
    );
}


// flex items-center justify-center w-full px-2 py-1 text-white 
// transition-colors duration-300 transform bg-blue-600 rounded-md 
// focus:outline-none sm:w-auto sm:mx-1 hover:bg-blue-500 
// focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-40


// text-white   
// hover:bg-blue-500 focus:bg-blue-500 
// focus:ring focus:ring-blue-300 focus:ring-opacity-40
// first-letter:transition-colors duration-300 
// transform rounded-md focus:outline-none  sm:mx-1              
// hover:text-white