import React, { Component } from "react";
import Spritesheet from "react-responsive-spritesheet";

import aniImageWalk from "../../../content/assets/building/ant_walk_spritesheet.png";
import aniImageWalkLTR from "../../../content/assets/building/ant_walk_LTR_spritesheet.png";
import aniImageCarry from "../../../content/assets/building/ant_carry_spritesheet.png";
import aniImageCarryLTR from "../../../content/assets/building/ant_carry_LTR_spritesheet.png";
import BuildingItem from "./BuildingItem";

class Ant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frameHeight: 256,
      frameWidth: 256,
      frameSteps: 10,
      frame: 0,
      opacity: 0,
    };
  }
  componentDidMount() {
    this.setState({ frame: 0 });

    setTimeout(() => {
      this.setState({ opacity: 1 });
    }, 500);
  }
  componentWillUnmount() {}

  getWrapperStyles() {
    // console.log('[ANT] rotate: '+this.props.rotate);
    // let opacity = 1.0;
    //console.log('ant  step:'+this.props.aniNum+'  of: '+(this.props.aniNumTotal - 1));
    // if (this.props.aniNum >= (this.props.aniNumTotal-1)){
    //   // last step
    //   console.log('ant last step ');
    // }
    let rotateDegree = 0;
    if (this.props.rotate === 1) {
      rotateDegree = 270;
    } else if (this.props.rotate === 2) {
      rotateDegree = 90;
    } else if (this.props.rotate === 3) {
      rotateDegree = -90;
    }
    return {
      zIndex: 700,
      width: 64,
      height: 64,
      opacity: this.state.opacity,
      transform: `scale(${this.props.scale}) rotateZ(${rotateDegree}deg)`,
    };
  }

  render() {
    // console.log('====================================');
    // console.log(this.props.carryingItem);
    // console.log('====================================');
    let imageToUse = aniImageWalk;
    if (this.props.aniNum > 0) {
      if (this.props.aniNum === 1) {
        // walk
        imageToUse = aniImageWalk;
      } else if (this.props.aniNum === 2) {
        // walk LTR
        imageToUse = aniImageWalkLTR;
      } else if (this.props.aniNum === 3) {
        // carry
        if (this.props.acornNumber !== 0) {
          imageToUse = aniImageCarry;
        } else {
          imageToUse = aniImageWalk;
        }
      } else if (this.props.aniNum === 4) {
        // carry LTR
        if (this.props.acornNumber !== 0) {
          imageToUse = aniImageCarryLTR;
        } else {
          imageToUse = aniImageWalkLTR;
        }
      }
    }

    let transformStyleRotate = ``;
    if (this.props.rotate === 1) {
      transformStyleRotate = `scale(0.7) rotateZ(270deg)`;
    } else if (this.props.rotate === 2) {
      transformStyleRotate = `scale(0.7) rotateZ(90deg)`;
    } else {
      transformStyleRotate = `scale(0.7) rotateZ(0deg)`;
    }
    // let transformStyleScale = `scale(${this.props.scale})`;

    // * BRICK *
    let carryItemPosTop = -10;
    let carryItemPosLeft = 15;
    if(this.props.removeItem === true){
      carryItemPosTop = -10;
      carryItemPosLeft = -25;
    }
    if (
      this.props.buildingItemType === 3 ||
      this.props.buildingItemType === 4 ||
      this.props.buildingItemType === 5
    ) {
      // * WINDOW *
      carryItemPosTop = -10;
      carryItemPosLeft = 25;
      if(this.props.removeItem === true){
        carryItemPosTop = -10;
        carryItemPosLeft = -25;
      }
    } else if (
      this.props.buildingItemType === 6 ||
      this.props.buildingItemType === 7 ||
      this.props.buildingItemType === 8
    ) {
      // * ROOF *
      carryItemPosTop = 0;
      carryItemPosLeft = 10;
      if(this.props.removeItem === true){
        carryItemPosTop = 0;
        carryItemPosLeft = -10;
      }
    } else if (this.props.buildingItemType === 9) {
      // * DOOR *
      carryItemPosTop = -20;
      carryItemPosLeft = 25;
      if(this.props.removeItem === true){
        carryItemPosTop = -20;
        carryItemPosLeft = -25;
      }
    }

    // **** DETERMINE IF BUILDING ITEM IS SHOW OR NOT ****
    let showItemCarried = false
    if (this.props.carryingItem === true && this.props.carryiedItemID !== "") {
      // ******* IS CARRYING...
      showItemCarried = true
    }
    return (
      <div
        className=""
        style={{
          position: "absolute",
          left: this.props.xPos,
          top: this.props.yPos,
          width: 64,
          height: 64,
          zIndex: 500,
          transition: `all ${this.props.transSec}s ease-out`,
        }}
      >
        <div style={this.getWrapperStyles()} className="">
          <div
            className=""
            style={{
              position: "absolute",
              left: carryItemPosLeft,
              top: carryItemPosTop,
              zIndex: 510,
              transform: "rotateZ(250deg)",
            }}
          >
            {this.props.buildingItemType !== undefined &&
              showItemCarried === true && (
                <BuildingItem
                  tier={this.props.tier}
                  buildingItemType={this.props.buildingItemType}
                />
              )}
          </div>
          <Spritesheet
            image={imageToUse}
            widthFrame={this.state.frameWidth}
            heightFrame={this.state.frameHeight}
            steps={this.state.frameSteps}
            fps={12}
            loop={true}
          />
        </div>
      </div>
    );
  }
}
export default Ant;
